import {InputBase} from "@mui/material";
import { InputStyles } from "./Input.styles";
import PropTypes from "prop-types";

export default function Input({id, placeholder, value, onChange, register, name, ...otherProperties}) {
  if(register) { // This input is only if you are using React-Hook-Form.
    return <InputBase sx={InputStyles} id={id} placeholder={placeholder} {...register(name)} {...otherProperties} />
  }
  // Generic input
  return <InputBase sx={InputStyles} id={id} name={name} value={value} placeholder={placeholder} onChange={onChange} {...otherProperties} />
}

Input.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  id: PropTypes.string,
  register: PropTypes.func,
  name: PropTypes.string,
}
