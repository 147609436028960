import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";

export default function Quotes({ cita, nombre, cargo, classname }) {
  
    return (
        <div className={"landings 2xl:mx-32 bg-white min-h-[12rem] my-8 lg:my-24 " + classname}>
            <div className="w-9/12 m-auto">
                <div className="text-green ml-4 w-16"><FontAwesomeIcon icon={faQuoteLeft} size="3x" /></div>
                <div className="leading-8 text-2xl text-center m-4" dangerouslySetInnerHTML={{ __html: cita }} />
                <div className="text-green mr-4 w-16 flex ml-auto"><FontAwesomeIcon icon={faQuoteRight} size="3x" /></div>
            </div>
            <div className="text-center w-52 m-auto">
                <div className="font-black border-b-4 border-red">{nombre}</div>
                <div>{cargo}</div>
            </div>
        </div>
    );
}

Quotes.propTypes = {
  cita: PropTypes.string,
  nombre: PropTypes.string,
  cargo: PropTypes.string,
};
