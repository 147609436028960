export const InputStyles = () => { 
  return {
    input: {
      '&::placeholder': {
        color: '#111827',
        fontWeight: 'bold'
      },
      color: '#10312B',
    },
    backgroundColor: 'white',
    borderWidth: '1px',
    borderColor: '#e1e1e1',
    borderStyle: 'solid',
    borderRadius: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    height: '100%',
    width: '100%',
  }; 
};
