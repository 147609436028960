import Banner from "components/molecules/Banner";
import { useRouter } from "next/router";
import { useEffect, useState } from 'react';
import formatNodeImages from "/lib/formatNodeImages";
import dynamic from 'next/dynamic';
import { InView } from 'react-intersection-observer';
import { ViewSlider, SliderCard, CardElements } from "components/molecules/Slider";
import { Skeleton } from '@mui/material';
import { customTitles } from '../../lib/utils';
import ContactForm from "./ContactForm";
import Quotes from "components/molecules/Quotes";

const ImagenFondoTexto = dynamic(() => import("components/molecules/ImagenFondoTexto"), {
  // loading: () => (
  //   <Skeleton
  //     animation="wave"
  //     variant="rectangle"
  //     width={500}
  //     height={500}
  //   />
  // ),
  ssr: false
});

const ImagenTextoPosicion = dynamic(() => import("components/molecules/ImagenTextoPosicion"), {
  loading: () => (
    <Skeleton
      className="relative 2xl:ml-32 2xl:mr-32 z-50 lg:flex lg:relative lg:h-[385px]"
      animation="wave"
      variant="rectangle"
      width="100%"
      sx={{
        height: "38rem",
        "@media (min-width: 1024px)": {
          height: "385px",
        },
      }}
    />
  ),
  ssr: true
});

const Barra = dynamic(() => import("components/molecules/Barra"), {
  loading: () => (
    <Skeleton
      className="relative z-50 w-full min-h-[12rem]"
      animation="wave"
      variant="rectangle"
      width="100%"
      sx={{
        height: "12rem",
        "@media (min-width: 1024px)": {
          height: "12rem",
        },
      }}
    />
  ),
  ssr: true
});

const Cards = dynamic(() => import("components/molecules/Card"), {
  // loading: () => (
  //   <Skeleton
  //     animation="wave"
  //     variant="rectangle"
  //     width={500}
  //     height={500}
  //   />
  // ),
  ssr: false
});

const LandingSlider = dynamic(() => import("components/molecules/Slider").then(mod => mod.LandingSlider), {
  // loading: () => (
  //   <Skeleton
  //     animation="wave"
  //     variant="rectangle"
  //     width={500}
  //     height={500}
  //   />
  // ),
  ssr: false
});

export default function NodeLandingPage({ node, inmuebles }) {
  const home = node.path?.alias === '/home';
  const Cliente = node.path?.alias === '/tenemos-un-cliente-para-ti';
  const vendedor = node.path?.alias === '/propuesta-vendedor';
  const clientes = node.path?.alias === "/propuesta-cliente";

  const [nodeInmueble, setNodeInmueble] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [isClient, setIsClient] = useState(false)
 
  useEffect(() => {
    setIsClient(true)
  }, [])
 

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  // getting Ad info for new customer landing page
  async function getAdvertisementResource(token) {
    try {
      const uuid = atob(token);
      const response = await fetch(
        `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/webform_rest/informacion_de_clientes/submission/${uuid}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      const nodeUUID = data.data.id_propiedad;
      const resourceInmueble = await fetch(
        `/api/getResources/node--inmueble/${nodeUUID}/teaser`
      );
      const dataInmueble = await resourceInmueble.json();
      return dataInmueble;
    } catch (e) {}
  }
  const router = useRouter();

  if(Cliente) {
    const { token } = router.query;
    getAdvertisementResource(token).then((resourceData) => {
      setNodeInmueble(resourceData);
    });
  }

  const { title } = customTitles.find(obj => obj.page = node.path?.alias) || { title: node.field_meta_title }

  return (
    <>
      <section className="bg-white 2xl:max-w-[1920px] 2xl:m-auto">
        <h1 className="hidden">{title}</h1>
        {node.field_elementos.map((element, key) => {
          if (element.type === "paragraph--banner")
            return (
              <Banner
                classname={element.type}
                key={key}
                node={element}
                mobileSrc={
                  element.field_imagen_banner?.field_media_image?.links?.mobile
                }
                tabletSrc={
                  element.field_imagen_banner?.field_media_image?.links?.tablet
                }
              desktopSrc={
                  element.field_imagen_banner?.field_media_image?.links?.desktop
                }
              desktopXlSrc={
                  element.field_imagen_banner?.field_media_image?.links?.desktop_xl
                }
                desktop2xlSrc={
                  element.field_imagen_banner?.field_media_image?.links?.desktop_2xl
                }
                imgUri={element.field_imagen_banner?.field_media_image?.resourceIdObjMeta
                  ?.alt}
                textBanner={element.field_texto?.value}
                titulo={element.field_titulo}
                uriButton={element.field_boton?.uri}
                titleButton={element.field_boton?.title}
                colorButton="#b12028"
                colorTextButton={element.field_color_texto_boton?.color}
                isHome={home}
              />
            );
          else if (element.type === "paragraph--imagen_fondo_texto")
            return (
              <InView triggerOnce key={key}>
                {({ inView, ref, entry }) => (
                  <div ref={ref}>
                    <div className="hidden">
                      {/*{ staticData() }*/}
                    </div>
                    { inView && (
                      <ImagenFondoTexto
                        classname={element.type}
                        key={key}
                        node={element}
                        mobileSrc={
                          element.field_imagen?.field_media_image?.links?.mobile
                            .href
                        }
                        tabletSrc={
                          element.field_imagen?.field_media_image?.links?.tablet
                            .href
                        }
                        desktopSrc={
                          element.field_imagen?.field_media_image?.links?.desktop
                            .href
                        }
                        desktopXlSrc={
                          element.field_imagen?.field_media_image?.links?.desktop_xl
                            .href
                        }
                        desktop2xlSrc={
                          element.field_imagen?.field_media_image?.links?.desktop_2xl
                            .href
                        }
                        position={element.field_posicion_texto}
                        linkColor={element.field_color_fondo_boton?.color}
                        text={element.field_texto?.value}
                        linkUri={element.field_boton?.uri}
                        textLink={element.field_boton?.title}
                      />
                    )}
                  </div>
                )}
              </InView>
            );
          else if (element.type === "paragraph--imagen_texto_posicion" && isClient )
            return (
              <ImagenTextoPosicion
                classname={element.type}
                key={key}
                node={element}
                mobileSrc={
                  element.field_imagen?.field_media_image?.links?.mobile
                }
                tabletSrc={
                  element.field_imagen?.field_media_image?.links?.tablet
                }
                desktopSrc={
                  element.field_imagen?.field_media_image?.links?.desktop
                }
                desktopXlSrc={
                  element.field_imagen?.field_media_image?.links?.desktop_xl
                }
                desktop2xlSrc={
                  element.field_imagen?.field_media_image?.links?.desktop_2xl
                }
                imgUri={
                  element.field_imagen?.field_media_image?.resourceIdObjMeta
                    ?.alt
                }
                imgPosition={element.field_posicion_imagen}
                text={element.field_texto?.value}
                buttonUri={element.field_boton?.uri}
                textButton={element.field_boton?.title}
                linkColor={element.field_color_fondo_boton?.color}
                title={element.field_titulo}
              />
            );
          else if (element.type === "paragraph--barra")
            return (
              <Barra
                classname={element.type}
                key={key}
                node={element}
                textBarra={element.field_texto?.value}
                url={element.field_boton?.uri}
                textLink={element.field_boton?.title}
                colorLink={element.field_color_fondo_boton?.color}
              />
            );
          else if (element.type === "paragraph--quotes")
            return (
              <Quotes
                classname={element.type}
                key={key}
                node={element}
                cita={element.field_cita}
                nombre={element.field_nombre}
                cargo={element.field_cargo_titulo}
              />
              );
          else if (element.type === "paragraph--slider") {
            return (() => {
              if (home) {
                return (
                  <ViewSlider key={key} inmuebles={inmuebles} classname={element.type} />
                );
              } else {
                return (
                  <>
                    {element.field_tipo_slider === "full_slider" ? (
                      <LandingSlider
                        key={key}
                        elementos={element.field_elementos}
                      />
                    ) : (
                      <>
                      {isLoading ? (
                          <Skeleton
                            className="relative left-1/2 -translate-x-1/2 w-[92%] h-[38rem] md:h-[33rem] mb-3 md:my-5"
                            animation="wave"
                            variant="rectangle"
                            width="100%"
                            sx={{
                              height: "38rem",
                              "@media (min-width: 1024px)": {
                                height: "33rem",
                              },
                            }}
                          />
                        ) : (
                          <CardElements
                            key={key}
                            elementos={element.field_elementos}
                            classname={element.type}
                          />
                        )}
                      </>
                    )}
                  </>
                );
              }
            })();
          }else
            return <div key={key}>{element.type} no esta ligado a ningun componente</div>;
        })}
        {node.field_mostrar_formulario ? 
        <div id="contacto" role="region" aria-label="contacto" className="flex justify-center mb-16 mt-12 2xl:ml-32 2xl:mr-32 bg-gray-light">
          <ContactForm title="Ponte en contacto con nosotros" node_title={node.title}/>
        </div> : ""}
      </section>
    </>
  );
}
