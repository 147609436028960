import Image from "next/image";
import dynamic from 'next/dynamic';
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const DCustomIcon = dynamic(() => import('../atoms/CustomIcon'),{ssr: false});

const ImageGallery = (images) => {
  const items = Object.values(images).flatMap((arr) => arr);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [showAllImages, setShowAllImages] = useState(false);

  const openModal = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
    setShowAllImages(true);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setCurrentIndex(0);
    setModalOpen(false);
  };

  useEffect(() => {
    setSrcMobile(items[currentIndex]?.uri || "/img/no-image-available.svg");
  }, [currentIndex, items]);


  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
    setSelectedImage(
      items[currentIndex === items.length - 1 ? 0 : currentIndex + 1]
    );
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    setSelectedImage(
      items[currentIndex === 0 ? items.length - 1 : currentIndex - 1]
    );
  };

  const onlyThreeImg = Object.values(items).slice(1, 4);

  const isMobile = useMediaQuery({ maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  const [srcMobile, setSrcMobile] = useState(items[currentIndex]?.uri || "/img/no-image-available.svg");
  const [srcDesktop, setSrcDesktop] = useState(items[0]?.uri || "/img/no-image-available.svg");
  const [srcDesktopMiniature, setSrcDesktopMiniature] = useState(null);
  const [srcDesktopModal, setSrcDesktopModal] = useState(null);

  return <>
    {isMobile && (
      <div className="flex relative">
        <button
          className="z-[1] absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer left-2.5"
          onClick={goToPrevious}
          aria-label="Avanzar a la imagen anterior"
        >
          <DCustomIcon
            iconName="chevronLeft"
            viewBox="0 0 50 50"
            sx={{
              background: "rgba(0, 0, 0, 0.6)",
              borderRadius: "9999px",
              fontSize: "3rem",
            }}
          />
        </button>
        <div className="w-full h-auto">
          <Image
            width={750}
            height={562}
            src={srcMobile}
            alt={items[currentIndex]?.alt}
            placeholder="blur"
            blurDataURL={srcMobile}
            priority={true}
            loading="eager"
            onError={() => setSrcMobile("/img/no-image-available.svg")}
            sizes="100vw"
            style={{
              width: "100%",
              height: "auto"
            }} />
        </div>
        <button
          className="z-[1] absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer right-2.5"
          onClick={goToNext}
          aria-label="Avanzar a la siguiente imagen"
        >
          <DCustomIcon
            iconName="chevronRight"
            viewBox="0 0 45 49"
            sx={{
              background: "rgba(0, 0, 0, 0.6)",
              borderRadius: "9999px",
              fontSize: "3rem",
            }}
          />
        </button>
      </div>
    )}
    {isDesktop && (
      <div className="flex">
        <div
          className="flex w-[70%] relative h-[400px] 2xl:h-[600px] lg:mr-2"
          onClick={() => openModal(items[0], 0)}
        >
          <Image
            className="hover:bg-white hover:bg-opacity-20 transition duration-300 cursor-pointer"
            src={srcDesktop}
            alt={items[0]?.alt}
            placeholder="blur"
            blurDataURL={srcDesktop}
            priority={true}
            loading="eager"
            onError={() => setSrcDesktop("/img/no-image-available.svg")}
            fill
            sizes="100vw"
            style={{
              objectFit: "cover"
            }} />
          <div className="absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-30 transition duration-300 cursor-pointer"></div>
        </div>
        <div className="flex flex-col w-[30%] h-[400px] 2xl:h-[600px]">
          {onlyThreeImg.map((image, index) => (
            <div
              key={index}
              className={`cursor-pointer relative h-[200px] ${
                index !== onlyThreeImg.length - 1 ? "mb-2" : ""
              }`}
              onClick={() => openModal(image, index)}
            >
              <Image
                src={srcDesktopMiniature ?? image.uri}
                alt={image.alt}
                placeholder="blur"
                blurDataURL={srcDesktopMiniature ?? image.uri}
                priority={true}
                loading="eager"
                onError={() =>
                  setSrcDesktopMiniature("/img/no-image-available.svg")
                }
                fill
                sizes="100vw"
                style={{
                  objectFit: "cover"
                }} />
              <div className="absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-30 transition duration-300 cursor-pointer"></div>
            </div>
          ))}
        </div>
        {modalOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-[1001]">
            <button
              className=" z-[1] absolute top-2.5 right-2.5 p-2.5 bg-transparent border-none text-white text-xl cursor-pointer"
              onClick={closeModal}
            >
              <DCustomIcon
                iconName="close"
                viewBox="0 0 45 49"
                sx={{ fontSize: "2rem" }}
              />
            </button>
            {showAllImages ? (
              <div className="relative flex items-center justify-center w-4/5 h-4/5">
                <button
                  className="z-[1] absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer left-2.5"
                  onClick={goToPrevious}
                >
                  <DCustomIcon
                    iconName="chevronLeft"
                    viewBox="0 0 50 50"
                    sx={{
                      background: "rgba(0, 0, 0, 0.6)",
                      borderRadius: "9999px",
                      fontSize: "3rem",
                    }}
                  />
                </button>
                <div className="w-[900px] h-[900px] relative">
                  <div className="absolute top-0 right-0 bottom-0 left-0">
                    <Image
                      src={srcDesktopModal ?? selectedImage.uri}
                      alt={selectedImage.alt}
                      placeholder="blur"
                      blurDataURL={srcDesktopMiniature ?? selectedImage.uri}
                      onError={() =>
                        setSrcDesktopModal("/img/no-image-available.svg")
                      }
                      fill
                      sizes="100vw"
                      style={{
                        objectFit: "contain"
                      }} />
                  </div>
                </div>
                <button
                  className="absolute top-2/4 transform translate-y-[-50%] p-2.5 bg-transparent border-none text-white text-xl cursor-pointer right-2.5"
                  onClick={goToNext}
                >
                  <DCustomIcon
                    iconName="chevronRight"
                    viewBox="0 0 45 49"
                    sx={{
                      background: "rgba(0, 0, 0, 0.6)",
                      borderRadius: "9999px",
                      fontSize: "3rem",
                    }}
                  />
                </button>
              </div>
            ) : (
              <Image
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={srcDesktopModal ?? selectedImage.uri}
                alt={selectedImage.alt}
                placeholder="blur"
                blurDataURL={srcDesktopMiniature ?? selectedImage.uri}
                onError={() =>
                  setSrcDesktopModal("/img/no-image-available.svg")
                }
                fill
                sizes="100vw" />
            )}
          </div>
        )}
      </div>
    )}
  </>;
};

export default ImageGallery;
