import { useState } from "react";
import ButtonPrimary from "components/atoms/Button";
import Input from "components/atoms/Input/Input";
import { CircularProgress } from "@mui/material";
import { useRouter } from "next/router";
import dynamic from 'next/dynamic';

const DCustomIcon = dynamic(() => import('../atoms/CustomIcon'), { ssr: false });

const ContactForm = ( {title, node_title} ) => {
  const [statusForm, setStatusForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telefono: "",
    message: "",
    node_title: node_title,
  });

  const router = useRouter();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangeName = (e) => {
    const { name, value } = e.target;
    const nameClean = value.replace(/[^a-zA-Z0-9 ]/, "");
    setFormData({ ...formData, [name]: nameClean });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`/api/contactoForm`, {
        method: "POST",
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setLoading(false);
      if (data.errors) {
        setStatusForm(data.errors[0]);
        return;
      }
      setStatusForm("Gracias nos pondremos en contacto contigo");
      router.push("/thankyou");
    } catch (e) {
      console.error("Error al enviar el formulario.", e.message);
    }
  };

  return (
    <form className="bg-gray-light p-5 lg:w-4/12">
      <h1 className="text-green lg:text-3xl font-bold flex justify-center mb-5">
        {title}
      </h1>
      {statusForm ? (
        statusForm === "Gracias nos pondremos en contacto contigo" ? (
          <div style={{ backgroundColor: "#b4d4bc" }} className="text-center text-green font-bold p-2">
            {statusForm}
          </div>
        ) : (
          <div style={{ backgroundColor: "#f2e6e6" }} className="text-center text-red font-bold p-2">
            {statusForm}
          </div>
        )
      ) : (
        " "
      )}
      <div>
        <label className="hidden" htmlFor="node_title">
          Titulo nodo
        </label>
        <input
          className="hidden"
          id="node_title"
          name="node_title"
          value={node_title}
          onChange={handleChange}
          placeholder="Titulo del nodo"
        />
      </div>
      <div className="my-8">
        <label className="hidden" htmlFor="name">Nombre Completo</label>
        <Input
          required
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChangeName}
          placeholder="Nombre Completo"
        />
      </div>
      <div className="my-8">
        <label className="hidden" htmlFor="telefono">Teléfono</label>
        <Input
          required
          id="telefono"
          name="telefono"
          onChange={handleChange}
          placeholder="Teléfono"
          type="text"
          size="35"
        />
      </div>
      <div className="my-8">
        <label className="hidden" htmlFor="email">Email</label>
        <Input
          required
          id="email"
          name="email"
          onChange={handleChange}
          placeholder="Email"
          type="text"
          size="35"
        />
      </div>
      <div className="mt-6">
        <label className="hidden" htmlFor="message">Mensaje</label>
        <textarea
          placeholder="Mensaje"
          id="message"
          name="message"
          onChange={handleChange}
          className="text-base w-full p-3 h-32 text-green rounded-[5px] focus:outline-none bg-white border border-gray-smooth placeholder:font-bold"
        />
      </div>
      {loading && (
        <div className="flex justify-center m-3">
          <CircularProgress color="inherit" />
        </div>
      )}
      <div className="flex justify-center mt-8 md:px-8">
        <ButtonPrimary
          textButton="Enviar"
          backgroundButton="#B12028"
          type="submit"
          onClick={submitForm}
          endIcon={<DCustomIcon iconName="chevronRight" viewBox="0 0 50 50" className={'text-white text-[1.75rem]'} />}
        />
      </div>
    </form>
  );
};

export default ContactForm;
